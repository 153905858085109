<template>
  <div>
    <div id="commercant-header" class="container-fluid">
      <div class="row flex-md-row-reverse">
        <div class="commercant-right col-12 col-lg-9">
          <agile
            :center-mode="true"
            :speed="1000"
            :autoplay="hasManySlides"
            :autoplay-speed="5000"
            :nav-buttons="false"
          >
            <template v-for="banner in banners">
              <div v-if="isBannerIframe(banner)" :key="banner.id" class="slide">
                <iframe
                  v-resize="{ checkOrigin: false, log: true }"
                  frameborder="0"
                  :src="getBannerUrl(banner)"
                />
              </div>
              <div v-else :key="banner.id" :style="getBannerImageStyle(banner)" class="slide image"></div>
            </template>
            <template v-if="!banners.length">
              <div v-for="(image, i) in shop.images" :key="i" :style="getBannerImageStyle({ image })" class="slide image"></div>
            </template>
          </agile>
        </div>
        <div class="commercant-left col-12 col-lg-3">
          <h1>{{ shop.name }}</h1>
          <div v-marked="shop.getDescriptionShort($l)"></div>
          <div v-if="shop.logo"><img :src="$resize(shop.logo.raw.url, { w: 250, h: 100, f: 'inside' })" /></div>
          <a
            href="#"
            @click="viewMore = !viewMore"
          >{{ $t('shop.moreInfo') }}</a>
        </div>
      </div>
    </div>

    <div
      class="commercant-plus"
      :class="{ active: viewMore }"
    >
      <div class="row flex-lg-row-reverse">
        <div class="commercant-content col-12 col-lg-9">
          <p v-marked="shop.getDescription($l)"></p>
        </div>
        <div class="commercant-photo col-12 col-lg-3">
          <img :src="$resize(moreImage, { w: 450, h: 450, f: 'cover' })">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueAgile } from 'vue-agile'
export default {
  name: 'CommercantHeader',
  components: {
    agile: VueAgile
  },
  data: () => ({
    viewMore: false
  }),
  computed: {
    shop() {
      return this.$store.getters['shop/current']
    },
    banners() {
      return this.$store.getters['shop/banners'](this.shop.id)
    },
    hasManySlides() {
      if (!this.banners.length) {
        return this.shop.images?.length > 1
      }
      return this.banners.length > 1
    },
    moreImage() {
      if (!this.shop.images?.length) {
        return
      }
      const image = this.shop.images[0]
      return image.squaremini?.url || image.squaresmall?.url || image.raw?.url
    }
  },

  methods: {
    getBannerUrl(banner) {
      return `${this.$config.infoSiteUrl}/banner/${banner.url}`
    },

    getBannerImageStyle(banner) {
      // :src="$resize(banner.image.raw.url, { w: 399, h: 266, f: 'contain', c: 'grey' })"
      const image = this.$resize(banner.image.raw.url, { w: 1643, h: 736, f: 'inside', c: 'grey' })
      return {
        backgroundImage: `url(${image})`
      }
    },

    isBannerIframe(banner) {
      return !!banner.url
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

#commercant-header {
  .commercant-left {
    color: white;
    background-color: var(--gray-color);
    text-align: left;
    padding: 40px;
    box-sizing: border-box;
    position: relative;
    text-align: center;

    @include media-breakpoint-up(lg) {
      text-align: left;
    }
    h1 {
      font-size: 30px;
      text-align: center;
      font-weight: 700;
      margin-top: 0;

      @include media-breakpoint-up(md) {
        font-size: 35px;
      }

      @include media-breakpoint-up(lg) {
        text-align: left;
        font-size: 40px;
      }
    }
    p,
    a {
      font-size: 16px;
      font-weight: 400;
      line-height: 1.8rem;

      @include media-breakpoint-up(md) {
        font-size: 18px;
      }
    }
    a {
      color: var(--red-color);
      text-align: center;
      text-decoration: none;
      display: block;
      font-weight: 900;
      padding-top: 20px;

      @include media-breakpoint-up(lg) {
        text-align: right;
        position: absolute;
        bottom: 20px;
        right: 50px;
      }
    }
    img {
      max-width: 250px;

      @include media-breakpoint-up(lg) {
        text-align: center;
        position: relative;
        max-width: 150px;
        padding-bottom: 50px;
      }

      @include media-breakpoint-up(xl) {
        padding: 0;
        float: left;
        position: absolute;
        max-width: 250px;
        bottom: 75px;
      }
    }
  }
  .commercant-right {
    position: relative;
    padding: 0;

    img {
      width: 100%;
    }
  }
}
::v-deep .prev,
::v-deep .next {
  display: none;

  @include media-breakpoint-up(md) {
    display: flex;
    color: transparent !important;
    width:30px !important;
    height: 70px !important;
    top: 200px !important;
    background-size: contain !important;
    filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.6)) !important;
    cursor: pointer;
  }

  @include media-breakpoint-up(lg) {
    width: 70px !important;
  }

}
::v-deep .prev {
  background: url("~@/assets/slider-prev-red.svg") no-repeat;
}

::v-deep .next {
  background: url("~@/assets/slider-next-red.svg") no-repeat;
}

.slide {
  height: 400px !important;

  @include media-breakpoint-up(md) {
    height: 500px !important;
  }

  @media (min-width: 1366px) {
    height: 700px !important;
  }

  iframe {
    border: 0;
    width: 100%;
    min-height: 400px;

    @include media-breakpoint-up(md) {
      min-height: 500px;
    }

    @media (min-width: 1366px) {
      min-height: 700px;
    }
  }

  &.image {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 400px;

    @include media-breakpoint-up(md) {
      min-height: 500px;
    }

    @media (min-width: 1024px) {
      min-height: 700px;
    }
  }

  img.d-lg-flex {
    width: 100%;
  }
}

.commercant-plus:not(.active) {
  display: none;
}
.commercant-plus {
  display: flex;
  background-color: var(--purple-color);
  padding: 40px;

  @include media-breakpoint-up(lg) {
    padding: 80px 20px;
  }
}
.commercant-photo img {
  width: 80%;
  border: 10px solid white;
  box-shadow: 0px 1px 7px #0000005e;

  @include media-breakpoint-up(md) {
    width: 60%;
  }
  @include media-breakpoint-up(md) {
    width: 100%;
  }
}
.commercant-content {
  color: white;
  text-align: center;

  @include media-breakpoint-up(lg) {
    padding: 0 80px;
    text-align: left;
  }
}
.commercant-content h2 {
  font-size: 20px;
  font-weight: 900;
  text-transform: initial;

  @include media-breakpoint-up(md) {
    font-size: 30px;
  }
}
.commercant-content p {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.8rem;
  padding: 20px 0;

  @include media-breakpoint-up(md) {
    font-size: 18px;
  }
}
</style>
